import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import Footer from '../../parts/Footer/Footer';
import Navbar from '../../parts/Navbar/Navbar';
import { GlobalStyles } from '../../themes/GlobalStyles';
import { theme } from '../../themes/theme';
import Drawer from '../Drawer/Drawer';
import Menu from '../Menu/Menu';
import SEO from '../SEO';
import { SectionsWrapper, StyledPage } from './Layout.styles';

const Layout = ({ children }) => (
    <>
        <SEO />
        <GlobalStyles />
        <ThemeProvider theme={theme}>
            <StyledPage>
                <Drawer />
                <Navbar />
                <SectionsWrapper>{children}</SectionsWrapper>
                <Footer />
                <Menu />
            </StyledPage>
        </ThemeProvider>
    </>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
