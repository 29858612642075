import React, { useEffect, useState } from 'react';
import Arrow from './Arrow/Arrow';
import Dots from './Dots/Dots';
import Slide from './Slide/Slide';
import { StyledSlider } from './Slider.styles';
import SliderContent from './SliderContent/SliderContent';

const Slider = ({ slides, inView, sliderType }) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (sliderType === 'projects') {
                if (window.innerWidth <= 992) {
                    setWidth(window.innerWidth);
                } else {
                    setWidth(1000);
                }
            } else {
                setWidth(window.innerWidth);
            }
            const updateWindowDimensions = () => {
                if (sliderType === 'projects') {
                    if (window.innerWidth <= 992) {
                        setWidth(window.innerWidth);
                    } else {
                        setWidth(1000);
                    }
                } else {
                    setWidth(window.innerWidth);
                }
            };

            window.addEventListener('resize', updateWindowDimensions);

            return () => window.removeEventListener('resize', updateWindowDimensions);
        }
    }, [sliderType]);

    const [state, setState] = useState({
        activeIndex: 0,
        translate: 0,
        transition: 0.45,
    });

    const { translate, transition, activeIndex } = state;

    const nextSlide = () => {
        if (activeIndex === slides.length - 1) {
            return setState({
                ...state,
                translate: 0,
                activeIndex: 0,
            });
        }

        setState({
            ...state,
            activeIndex: activeIndex + 1,
            translate: (activeIndex + 1) * width,
        });
    };

    const prevSlide = () => {
        if (activeIndex === 0) {
            return setState({
                ...state,
                translate: (slides.length - 1) * width,
                activeIndex: slides.length - 1,
            });
        }

        setState({
            ...state,
            activeIndex: activeIndex - 1,
            translate: (activeIndex - 1) * width,
        });
    };

    const selectSlide = index => {
        setState({
            ...state,
            activeIndex: index,
            translate: index * width,
        });
    };

    return (
        <StyledSlider width={width} inView={inView} type={sliderType}>
            <SliderContent translate={translate} transition={transition} width={width * slides.length}>
                {slides.map((slide, i) => (
                    <Slide
                        key={i}
                        content={slide}
                        indexKey={i + activeIndex}
                        sliderType={sliderType}
                        slideOpacity={i === activeIndex}
                    />
                ))}
            </SliderContent>

            <Arrow direction={'left'} onClick={prevSlide} />
            <Arrow direction={'right'} onClick={nextSlide} />

            <Dots slides={slides} activeIndex={activeIndex} selectSlide={selectSlide} />
        </StyledSlider>
    );
};

Slider.defaultProps = {
    sliderType: 'projects',
};

export default Slider;
