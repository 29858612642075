export const validateRecaptcha = recaptcha =>
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/contact`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ recaptcha }),
    });

export const fetchGithubStats = () => fetch(`${process.env.REACT_APP_GITHUB_API}`).then(res => res.json());
