import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { CgMail } from 'react-icons/cg';
import contact from '../../utils/data/contact';
import { validateRecaptcha } from '../../utils/helpers/fetchData';
import { contactFormValidation } from '../../utils/validators/contactFormValidation';
import {
    ButtonsContainer,
    Colored,
    ContactContainer,
    ContactInput,
    ContactSubmitButton,
    ContactSubmitButtonSign,
    ContactTextArea,
    InputContainer,
    ReCaptchaWrapper,
    TextError,
} from './ContactForm.styles';

const ContactForm = () => {
    const { t, i18n } = useTranslation('common');
    const recaptchaRef = React.createRef();
    const [recaptchaId, setRecaptchaId] = useState(0);

    useEffect(() => {
        recaptchaRef.current.reset();
    }, [recaptchaRef]);

    return (
        <Formik
            initialValues={{ subject: '', body: '', recaptcha: '' }}
            validationSchema={contactFormValidation}
            validateOnMount={true}
            onSubmit={(values, { setSubmitting, resetForm, validateForm }) => {
                validateRecaptcha(values.recaptcha)
                    .then(res => res.json())
                    .then(res => {
                        if (res.recaptcha_validation && typeof window !== 'undefined') {
                            setRecaptchaId(currentId => currentId + 1);
                            window.open(
                                `mailto:${contact.email}?subject=${values.subject}&body=${values.body}`,
                                '_self'
                            );
                        }
                    })
                    .finally(() => {
                        setSubmitting(false);
                        resetForm();
                        validateForm();
                    });
            }}>
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                isValid,
                setFieldValue,
                resetForm,
                validateForm,
            }) => (
                <ContactContainer onSubmit={handleSubmit}>
                    <InputContainer>
                        <CgMail
                            style={{
                                backgroundColor: 'darkred',
                                color: 'white',
                                width: 60,
                            }}
                            size={30}
                        />
                        <ContactInput
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={t('subject')}
                            name={'subject'}
                            value={values.subject}
                            required
                        />
                    </InputContainer>
                    {touched.subject && errors.subject && (
                        <TextError>{t(errors.subject.toString())}</TextError>
                    )}
                    <ContactTextArea
                        onChange={handleChange}
                        name={'body'}
                        value={values.body}
                        onBlur={handleBlur}
                        placeholder={`${t('body')}...`}
                        required
                    />
                    {touched.body && errors.body && <TextError>{t(errors.body.toString())}</TextError>}
                    <ReCaptchaWrapper>
                        <ReCAPTCHA
                            id={'recaptcha'}
                            key={`recaptcha-${i18n.language}-${recaptchaId}`}
                            ref={recaptchaRef}
                            onChange={val => setFieldValue('recaptcha', val)}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
                            hl={i18n.language}
                        />
                    </ReCaptchaWrapper>
                    <ButtonsContainer>
                        <ContactSubmitButton type={'submit'} disabled={!isValid}>
                            <Colored>{'// '}</Colored>
                            {t('send')}
                            <ContactSubmitButtonSign />
                        </ContactSubmitButton>
                        <ContactSubmitButton
                            type={'reset'}
                            onClick={() => {
                                resetForm({});
                                validateForm();
                                recaptchaRef.current.reset();
                            }}>
                            <Colored>{'// '}</Colored>
                            {t('clear')}
                            <ContactSubmitButtonSign />
                        </ContactSubmitButton>
                    </ButtonsContainer>
                </ContactContainer>
            )}
        </Formik>
    );
};

export default ContactForm;
