import React from 'react';
import WebsitesSection from './WebsitesSection/WebsitesSection';
import ProjectsSection from './ProjectsSection/ProjectsSection';
import CompetitionsSection from './CompetitionsSection/CompetitionsSection';
import ProjectsStatistics from './ProjectsStatistics/ProjectsStatistics';
import ProgrammingBox from './ProgrammingBox/ProgrammingBox';

const Projects = () => (
    <div id={'projects'}>
        <ProjectsSection />
        <ProjectsStatistics />
        <WebsitesSection />
        <ProgrammingBox />
        <CompetitionsSection />
    </div>
);

export default Projects;
