import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLogo, StyledNav, StyledNavLink, StyledNavLinkContainer } from './Navbar.styles';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import HamburgerMenu from '../../components/HamburgerMenu/HamburgerMenu';
import useScrollIndicator from '../../hooks/useScrollIndicator';

const Navbar = () => {
    const { t } = useTranslation('common');
    const scrollState = useScrollIndicator(50);

    return (
        <StyledNav scrollState={scrollState}>
            <NavLogo resizable />
            <StyledNavLinkContainer>
                <StyledNavLink href={'/#'}>{t('home')}</StyledNavLink>
                <StyledNavLink href={'/#projects'}>{t('projects')}</StyledNavLink>
                <StyledNavLink href={'/#skills'}>{t('skills')}</StyledNavLink>
                <StyledNavLink href={'/#hobby'}>{t('hobby')}</StyledNavLink>
                <StyledNavLink href={'/#contact'}>{t('contact')}</StyledNavLink>
                <LanguageSelector style={{ marginLeft: 40, marginRight: 40 }} />
            </StyledNavLinkContainer>
            <HamburgerMenu />
        </StyledNav>
    );
};

export default Navbar;
