import React from 'react';
import { faDiscord, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
    MenuItem,
    StyledColoredMenuText,
    StyledIcon,
    StyledMenu,
    StyledMenuLink,
    StyledMenuText,
    StyledMenuWrapper,
} from './Menu.styles';
import contact from '../../utils/data/contact';

const Menu = () => (
    <StyledMenu>
        <MenuItem>
            <StyledMenuLink href={`mailto:${contact.email}`} target={'_blank'}>
                <StyledIcon icon={faEnvelope} />
            </StyledMenuLink>
        </MenuItem>
        <MenuItem>
            <StyledMenuLink href={contact.github} target={'_blank'}>
                <StyledIcon icon={faGithub} />
            </StyledMenuLink>
        </MenuItem>
        <MenuItem expandable>
            <StyledIcon icon={faDiscord} />
            <StyledMenuWrapper href={'/#contact'}>
                <StyledMenuText>
                    {'Discord: '}
                    <StyledColoredMenuText>{contact.discord}</StyledColoredMenuText>
                </StyledMenuText>
            </StyledMenuWrapper>
        </MenuItem>
        <MenuItem>
            <StyledMenuLink href={contact.linkedin} target={'_blank'}>
                <StyledIcon icon={faLinkedin} />
            </StyledMenuLink>
        </MenuItem>
    </StyledMenu>
);

export default Menu;
