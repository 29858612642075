import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Section from '../../../components/Section/Section';
import useMobileWidth from '../../../hooks/useMobileWidth';
import { websites } from '../../../utils/data/projects';
import playAnimations from '../../../utils/helpers/playAnimations';
import WebsiteItem from './WebsiteItem/WebsiteItem';
import { WebsitesSectionContainer } from './WebsitesSection.styles';

const WebsitesSection = () => {
    const websiteItemSizes = { width: 437, height: 245 };
    const isMobileWidth = useMobileWidth();

    const generateStyles = (index, websiteItemSizes) => {
        if (!isMobileWidth) {
            if (index === 0) {
                return {
                    width: websiteItemSizes.width * 0.6,
                    height: websiteItemSizes.height * 0.6,
                    marginRight: -80,
                    zIndex: 3,
                };
            }

            if (index === 1) {
                return {
                    width: websiteItemSizes.width * 0.8,
                    height: websiteItemSizes.height * 0.8,
                    marginRight: -80,
                    zIndex: 4,
                };
            }

            if (index === 3) {
                return {
                    width: websiteItemSizes.width * 0.8,
                    height: websiteItemSizes.height * 0.8,
                    marginLeft: -80,
                    zIndex: 4,
                };
            }

            if (index === 4) {
                return {
                    width: websiteItemSizes.width * 0.6,
                    height: websiteItemSizes.height * 0.6,
                    marginLeft: -80,
                    zIndex: 3,
                };
            }

            return {
                ...websiteItemSizes,
                zIndex: 5,
            };
        }

        return {
            margin: 15,
        };
    };

    const generateWebsites = () =>
        websites.map((e, index) => (
            <WebsiteItem
                key={index + e.name}
                name={e.name}
                description={e.description}
                websiteImage={e.image}
                url={e.url}
                color={e.color}
                backgroundColor={e.backgroundColor}
                style={generateStyles(index, websiteItemSizes)}
            />
        ));

    const { t } = useTranslation('common');

    const { ref, inView } = useInView({
        threshold: 0.45,
        triggerOnce: true,
    });

    return (
        <div ref={ref}>
            <Section
                waves={2}
                inView={playAnimations(inView, isMobileWidth)}
                subtitle={t('projects')}
                title={t('projects_internet_apps')}
                text={t('projects_internet_apps_text')}>
                <WebsitesSectionContainer inView={playAnimations(inView, isMobileWidth)}>
                    {generateWebsites()}
                </WebsitesSectionContainer>
            </Section>
        </div>
    );
};

export default WebsitesSection;
