import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEO = () => {
    const { i18n } = useTranslation('common');

    return <Helmet title={'Ernest Bieś - portfolio'} htmlAttributes={{ lang: i18n.language }} />;
};

export default SEO;
