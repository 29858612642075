import React from 'react';
import { DotsWrapper, StyledDot, StyledDots } from './Dots.styles';

const Dots = ({ slides, activeIndex, selectSlide }) => (
    <DotsWrapper>
        <StyledDots>
            {slides.map((slide, i) => (
                <StyledDot key={i} active={activeIndex === i} onClick={() => selectSlide(i)} />
            ))}
        </StyledDots>
    </DotsWrapper>
);

export default Dots;
