import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import Section from '../../../components/Section/Section';
import Slider from '../../../components/Slider/Slider';
import { projects } from '../../../utils/data/projects';
import contact from '../../../utils/data/contact';
import playAnimations from '../../../utils/helpers/playAnimations';
import useMobileWidth from '../../../hooks/useMobileWidth';

const ProjectsSection = () => {
    const { t } = useTranslation('common');
    const isMobileWidth = useMobileWidth();

    const { ref, inView } = useInView({
        threshold: 0.45,
        triggerOnce: true,
    });

    return (
        <div ref={ref}>
            <Section
                waves={1}
                inView={playAnimations(inView, isMobileWidth)}
                subtitle={t('projects')}
                title={t('projects_main')}
                text={t('projects_main_text')}
                mobilePadding={false}
                replaceTextByHtml={{
                    wordToReplace: 'GitHub',
                    link: contact.github,
                }}>
                <Slider slides={projects} inView={playAnimations(inView, isMobileWidth)} />
            </Section>
        </div>
    );
};

export default ProjectsSection;
