import React from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from 'react-i18next';
import { skills } from '../../../utils/data/skills';
import CollapsibleTrigger from '../CollapsibleTrigger/CollapsibleTrigger';
import {
    CollapsibleSection,
    IconContainer,
    ItemIcon,
    StyledCollapsibleContainer,
} from './CollapsibleItem.styles';
import './CollapsibleItem.styles.css';

const CollapsibleItem = ({ type }) => {
    const { t } = useTranslation('common');

    const renderIcons = skillType =>
        skills[type][skillType].map((e, index) => (
            <ItemIcon key={index + e.name} src={e.icon} title={e.name} />
        ));

    return (
        <Collapsible
            trigger={CollapsibleTrigger({ text: t('more_info'), open: false })}
            triggerWhenOpen={CollapsibleTrigger({
                text: t('more_info'),
                open: true,
            })}
            className={'collapsible-item'}
            openedClassName={'collapsible-item'}>
            <StyledCollapsibleContainer>
                {skills[type]?.languages && (
                    <>
                        <CollapsibleSection>{t('languages')}</CollapsibleSection>
                        <IconContainer>{renderIcons('languages')}</IconContainer>
                    </>
                )}
                {skills[type]?.technologies && (
                    <>
                        <CollapsibleSection>{t('technologies')}</CollapsibleSection>
                        <IconContainer>{renderIcons('technologies')}</IconContainer>
                    </>
                )}
                {skills[type]?.env && (
                    <>
                        <CollapsibleSection>{t('env')}</CollapsibleSection>
                        <IconContainer>{renderIcons('env')}</IconContainer>
                    </>
                )}
            </StyledCollapsibleContainer>
        </Collapsible>
    );
};

export default CollapsibleItem;
