import React from 'react';

const replaceText = (text, wordToReplace, link) =>
    text.replace(
        wordToReplace,
        `<a style="color: #DC143C" href=${link} target="_blank">${wordToReplace}</a>`
    );

const replaceTextByHTML = (text, wordToReplace, link) => (
    <span
        dangerouslySetInnerHTML={{
            __html: replaceText(text, wordToReplace, link),
        }}
    />
);

export default replaceTextByHTML;
