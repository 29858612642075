import styled, { css } from 'styled-components';
import Logo from '../../components/Logo/Logo';

export const StyledNav = styled.nav`
    display: flex;
    align-items: center;
    background-color: white;
    height: 70px;
    position: sticky;
    top: 0;
    z-index: 10;

    ${({ scrollState }) =>
        scrollState === 'scrolled' &&
        css`
            box-shadow: 0 1px 10px 1px rgb(0 0 0 / 10%);
        `};
`;

export const StyledNavLinkContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: auto;

    ${({ theme }) => theme.mediaBreakpoints.navbarChange} {
        display: none;
    }
`;

export const StyledNavLink = styled.a`
    font-family: ${({ theme }) => theme.font.family.RobotoCondensed};
    font-size: ${({ theme }) => theme.font.size.md};
    font-weight: ${({ theme }) => theme.font.weight.light};
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: black;
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
    padding-bottom: 2px;
    background-image: linear-gradient(#dc143c, #dc143c);
    background-position: 0 100%;
    background-size: 0% 1px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s,
        color 0.3s;

    &:hover {
        color: #dc143c;
        background-position: 100% 100%;
        background-size: 100% 1px;
    }
`;

export const NavLogo = styled(Logo)`
    margin-left: 15px;

    ${({ theme }) => theme.mediaBreakpoints.sm} {
        transform: scale(0.8);
        margin-left: 0;
    }

    ${({ theme }) => theme.mediaBreakpoints.mobile} {
        transform: scale(0.8);
        margin: 0 auto;
    }
`;
