import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import Section from '../../components/Section/Section';
import ContactContainer from './ContactContainer/ContactContainer';
import contact from '../../utils/data/contact';
import playAnimations from '../../utils/helpers/playAnimations';
import useMobileWidth from '../../hooks/useMobileWidth';

const Contact = () => {
    const { t } = useTranslation('common');
    const isMobile = useMobileWidth();

    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
    });

    return (
        <div ref={ref} id={'contact'}>
            <Section
                backgroundColor={'#f5f5f5'}
                inView={playAnimations(inView, isMobile)}
                subtitle={t('contact')}
                title={t('collaboration')}
                text={t('contact_text')}
                style={{ paddingBottom: 0 }}
                replaceTextByHtml={{
                    wordToReplace: 'e-mail',
                    link: `mailto:${contact.email}`,
                }}>
                <ContactContainer inView={playAnimations(inView, isMobile)} />
            </Section>
        </div>
    );
};

export default Contact;
