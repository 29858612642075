import eSports from '../../assets/videos/esports.mp4';
import programming from '../../assets/videos/programming.mp4';
import movies from '../../assets/videos/movies.mp4';
import music from '../../assets/videos/music.mp4';
import friends from '../../assets/videos/friends.mp4';

export const hobby = [
    {
        id: 0,
        name: {
            en: 'Programming',
            pl: 'Programowanie',
        },
        quote: {
            en: 'The only way to learn a new programming language is by writing programs in it...',
            pl: 'Jedynym sposobem na nauczenie się języka programowania jest pisanie w nim programów...',
        },
        text: {
            en: 'From an early age, I was interested in news related to the world of computers and programming. As a young boy, I ran my own servers in online games that were very popular all over the country. It was the first time I encountered the Java programming language. Currently, I am interested in programming mobile and internet applications. I try to create applications with an attractive and user-friendly interface. I am constantly trying to deepen my knowledge in the field of web application development.',
            pl: 'Od najmłodszych lat interesowały mnie wiadomości związane ze światem komputerów i programowania. Jako młody chłopiec prowadziłem własne serwery w grach on-line, które były bardzo popularne w całym kraju. Był to pierwszy raz, kiedy zetknąłem się z językiem programowania Java. Obecnie interesuje mnie programowanie aplikacji mobilnych i internetowych. Staram się tworzyć aplikacje o atrakcyjnym i przyjaznym dla użytkownika interfejsem. Cały czas staram się pogłębiać swoją wiedzę z zakresu tworzenia aplikacji internetowych.',
        },
        intro: programming,
        keywords: {
            en: ['code', 'fix', 'develop'],
            pl: ['programuj', 'poprawiaj', 'rozwijaj'],
        },
    },
    {
        id: 1,
        name: {
            en: 'E-sports & gaming',
            pl: 'E-sport & gaming',
        },
        quote: {
            en: 'Practise until your idols become your rivals...',
            pl: 'Ćwicz, aż twoi idole staną się twoimi rywalami...',
        },
        text: {
            en: 'I have been interested in e-sports and computer games since I was a child. E-sports games are associated with emotions, competition and the desire to constantly improve your skills. These things are not foreign to me. I competed with the best players from around the world in games such as Counter Strike, H1Z1, Fortnite, Apex Legends and VALORANT. E-sport has taught me the desire for continuous development, drawing conclusions and teamwork which is a key element of every e-sport game. During the online games, I also had the opportunity to meet many people from around the world with whom I have very good contact on a daily basis.',
            pl: 'E-sportem i grami komputerowymi interesowałem się od dziecka. Gry e-sportowe związane są z emocjami, rywalizacją i chęcią ciągłego doskonalenia swoich umiejętności. Rzeczy te nie są mi obce. Rywalizowałem z najlepszymi graczami z całego świata w takich grach jak Counter Strike, H1Z1, Fortnite, Apex Legends czy VALORANT. E-sport nauczył mnie chęci ciągłego rozwoju, wyciągania wniosków i pracy zespołowej, która jest kluczowym elementem każdej gry e-sportowej. Podczas gier on-line miałem również okazję poznać wiele osób z całego świata, z którymi na co dzień mam bardzo dobry kontakt.',
        },
        intro: eSports,
        keywords: {
            en: ['learn', 'compete', 'win'],
            pl: ['poznawaj', 'rywalizuj', 'zwyciężaj'],
        },
    },
    {
        id: 2,
        name: {
            en: 'Cinematography',
            pl: 'Kinematografia',
        },
        quote: {
            en: 'Watching movies is a chance to live many lifetimes...',
            pl: 'Oglądanie filmów to szansa na przeżycie wielu wcieleń...',
        },
        text: {
            en: 'I have been interested in the world of cinema since childhood. In my opinion, watching movies is a chance to get to know many cultures and customs from around the world. Watching movies and series allows you to develop your imagination and the desire to get to know the world. I mainly like watching action movies, dramas and thrillers. Mind-blowing psychological thrillers are my favorites. While watching, the viewer must focus on the plot, think and try to find a solution to a specific puzzle. My favorite movies of this type are Prisoners, The Game, Inception and Split. Sometimes I also like to laugh, have fun and watch a good comedy! ;-)',
            pl: 'Światem kina interesuję się od dziecka. Moim zdaniem oglądanie filmów to szansa na poznanie wielu kultur i zwyczajów z całego świata. Oglądanie filmów i seriali pozwala rozwinąć wyobraźnię i chęć poznawania świata. Głównie lubię oglądać filmy akcji, dramaty i thrillery. Thrillery psychologiczne to mój ulubiony gatunek. Podczas oglądania widz musi skupić się na fabule, przemyśleć i spróbować znaleźć rozwiązanie określonej zagadki. Moje ulubione filmy tego typu to Labirynt, Gra, Incepcja i Split. Czasami lubię się też pośmiać, rozerwać i oglądnać dobrą komedię! ;-)',
        },
        intro: movies,
        keywords: {
            en: ['watch', 'discover', 'experience'],
            pl: ['oglądaj', 'odkrywaj', 'przeżywaj'],
        },
    },
    {
        id: 3,
        name: {
            en: 'Music',
            pl: 'Muzyka',
        },
        quote: {
            en: 'Music is the shorthand of emotion...',
            pl: 'Muzyka jest skrótem emocji...',
        },
        text: {
            en: "I really like listening to music in my spare time. Sometimes I also listen while working. Music relaxes me and puts me in a good mood. I appreciate songs that have a message in them. I don't have my favorite genre of music, I listen to different kinds of music depending on my mood. I have created my own playlists suitable for various occasions.",
            pl: 'W wolnym czasie bardzo lubię słuchać muzyki. Czasami słucham też podczas pracy. Muzyka relaksuje i wprawia mnie w dobry nastrój. Doceniam piosenki, które mają w sobie przesłanie. Nie mam swojego ulubionego gatunku muzycznego, słucham różnych gatunków muzyki w zależności od nastroju. Stworzyłem własne playlisty odpowiednie na różne okazje.',
        },
        intro: music,
        keywords: {
            en: ['listen', 'feel', 'thrill'],
            pl: ['słuchaj', 'przeżywaj', 'czuj'],
        },
    },
    {
        id: 4,
        name: {
            en: 'Time with friends',
            pl: 'Czas z przyjaciółmi',
        },
        quote: {
            en: 'Good time + friends = amazing memories...',
            pl: 'Dobry czas + przyjaciele = niesamowite wspomnienia...',
        },
        text: {
            en: 'Time spent with friends is valuable to me, it gives me a lot of satisfaction. Trips and walks are the best form of relaxation. People are my inspiration and motivation, I like to spend my free time with them. Friendship is the most important thing in life.',
            pl: 'Czas spędzony z przyjaciółmi jest dla mnie wartościowy, daje mi dużo zadowolenia. Wycieczki, spacery to najlepsza forma relaksu. Ludzie są dla mnie inspiracją, motywacją, lubię spędzać z nimi wolne chwile. Przyjaźń jest w życiu najważniejsza.',
        },
        intro: friends,
        keywords: {
            en: ['friends', 'gladness', 'memories'],
            pl: ['przyjaciele', 'radość', 'wspomnienia'],
        },
    },
];
