import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faDatabase, faServer, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SkillsElement, SkillSubtext, SkillText, StyledItem } from './SkillsItem.styles';
import { theme } from '../../themes/theme';
import CollapsibleItem from './CollapsibleItem/CollapsibleItem';

const SkillsItem = ({ animate, type }) => {
    const { i18n } = useTranslation('common');

    const skillsTypes = {
        frontend: {
            name: 'Frontend',
            desc: {
                pl: 'Projektowanie wyglądu i struktury aplikacji internetowych oraz mobilnych.',
                en: 'Designing the appearance and structure of web/mobile applications.',
            },
            icon: faCode,
        },
        backend: {
            name: 'Apps & Backend',
            desc: {
                pl: 'Projektowanie prostych aplikacji, serwerów API i modelowanie systemów.',
                en: 'Designing simple applications, API backend servers and system modeling.',
            },
            icon: faServer,
        },
        databases: {
            name: 'Databases',
            desc: {
                pl: 'Projektowanie i zarządzanie relacyjnymi oraz nierelacyjnymi bazami danych.',
                en: 'Designing and management of relational and non-relational databases.',
            },
            icon: faDatabase,
        },
        testing: {
            name: 'Testing & Deployment',
            desc: {
                pl: 'Testowanie aplikacji, przestrzeganie zasad czystego kodu i deployment aplikacji.',
                en: 'Testing the application, adherence to clean code principles and apps deployment.',
            },
            icon: faSearch,
        },
    };

    return (
        <SkillsElement>
            <StyledItem animate={animate}>
                <FontAwesomeIcon
                    icon={skillsTypes[type].icon}
                    color={theme.colors.secondary}
                    style={{ width: 50, height: 50, marginTop: 30 }}
                />
                <SkillText>{skillsTypes[type].name}</SkillText>
                <SkillSubtext>{skillsTypes[type].desc[i18n.language]}</SkillSubtext>
                <CollapsibleItem type={type} />
            </StyledItem>
        </SkillsElement>
    );
};

export default SkillsItem;
