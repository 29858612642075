import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    PageText,
    PageTitle,
    PageTitleWrapper,
    StyledImage,
    StyledIntro,
    StyledTitle,
    TextContainer,
} from './Intro.styles';
import { info } from '../../utils/data/information';
import ColoredText from '../../components/ColoredText/ColoredText';
import StyledButton from '../../components/StyledButton/StyledButton';
import introImage from '../../assets/images/ernestbies.png';

const Intro = () => {
    const { i18n, t } = useTranslation('common');

    return (
        <StyledIntro>
            <StyledImage src={introImage} decoding="async" />
            <TextContainer>
                <StyledTitle>{'Ernest Bieś'}</StyledTitle>
                <PageTitleWrapper>
                    <PageTitle>
                        {t('it_specialist')}
                        <ColoredText>{' // '}</ColoredText>
                        {t('programmer')}
                    </PageTitle>
                </PageTitleWrapper>
                <PageText>{info[i18n.language]}</PageText>
                <StyledButton link={'#contact'} title={t('contact')} />
            </TextContainer>
        </StyledIntro>
    );
};

export default Intro;
