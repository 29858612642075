import { faAward } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillWindows } from 'react-icons/ai';
import { FaGithub, FaYoutube } from 'react-icons/fa';
import { FcLinux } from 'react-icons/fc';
import {
    AwardIcon,
    BottomItem,
    CompetitionIcon,
    CompetitionInfo,
    CompetitionSubtitle,
    CompetitionTitle,
    IconTechWrapper,
    IconWrapper,
    Line,
    LinksBlock,
    ProjectDesc,
    ProjectImage,
    ProjectInfo,
    ProjectTitle,
    StyledCompetitionItem,
    StyledIcon,
} from './CompetitionItem.styles';

const CompetitionItem = ({ name, image, description, competition, links, technologies, order }) => {
    const { i18n } = useTranslation('common');

    const iconStyle = {
        width: 25,
        height: 25,
    };

    const renderLinksBlock = () =>
        links.map((e, index) => {
            if (e.name === 'Github') {
                return (
                    <IconWrapper key={index} href={e.url} target="_blank" rel="noreferrer">
                        <FaGithub color="black" style={iconStyle} />
                    </IconWrapper>
                );
            }

            if (e.name === 'Youtube') {
                return (
                    <IconWrapper key={index} href={e.url} target="_blank" rel="noreferrer">
                        <FaYoutube color="red" style={iconStyle} />
                    </IconWrapper>
                );
            }

            return (
                <IconWrapper key={index} href={e.url} target="_blank" rel="noreferrer">
                    <StyledIcon src={e.image} />
                </IconWrapper>
            );
        });

    const renderTechnologiesBlock = () =>
        technologies.map((e, index) => {
            if (e.name.includes('Windows')) {
                return (
                    <IconTechWrapper title={e.name} key={index}>
                        <AiFillWindows size={25} />
                    </IconTechWrapper>
                );
            }

            if (e.name.includes('Linux')) {
                return (
                    <IconTechWrapper title={e.name} key={index}>
                        <FcLinux size={25} />
                    </IconTechWrapper>
                );
            }

            return (
                <IconTechWrapper title={e.name} key={index}>
                    <StyledIcon src={e.logo} />
                </IconTechWrapper>
            );
        });

    return (
        <StyledCompetitionItem order={order}>
            <ProjectImage image={image} order={order} />
            <ProjectInfo order={order}>
                <ProjectTitle>{name}</ProjectTitle>
                <CompetitionInfo>
                    <CompetitionIcon src={competition.image} />
                    <CompetitionTitle>{competition.name}</CompetitionTitle>
                </CompetitionInfo>
                <CompetitionInfo>
                    <AwardIcon icon={faAward} />
                    <CompetitionSubtitle>{competition.text[i18n.language]}</CompetitionSubtitle>
                </CompetitionInfo>
                <ProjectDesc>{description[i18n.language]}</ProjectDesc>
                <BottomItem>
                    <LinksBlock>{renderLinksBlock()}</LinksBlock>
                    <Line />
                    <LinksBlock>{renderTechnologiesBlock()}</LinksBlock>
                </BottomItem>
            </ProjectInfo>
        </StyledCompetitionItem>
    );
};

export default CompetitionItem;
