import React from 'react';
import Layout from '../components/Layout/Layout';
import ScrollToUp from '../components/ScrollToUp/ScrollToUp';
import Contact from '../sections/Contact/Contact';
import Hobby from '../sections/Hobby/Hobby';
import Intro from '../sections/Intro/Intro';
import Projects from '../sections/Projects/Projects';
import Skills from '../sections/Skills/Skills';

const Home = () => (
    <Layout>
        <Intro />
        <Projects />
        <Skills />
        <Hobby />
        <Contact />
        <ScrollToUp />
    </Layout>
);

export default Home;
