import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WEBSITE_URL } from '../../utils/data/information';
import { Img, LogoContainer, TitleContainer, WebsiteSubtitle, WebsiteTitle } from './Logo.styles';

const Logo = ({ size, style, resizable, className }) => {
    const { t } = useTranslation('common');

    return (
        <LogoContainer
            href={`https://${WEBSITE_URL}`}
            style={style}
            className={className}
            title={'Ernest Bieś - portfolio'}>
            <Img src={'logo.svg'} />
            <TitleContainer size={size} resizable={resizable}>
                <WebsiteTitle>{WEBSITE_URL}</WebsiteTitle>
                <WebsiteSubtitle>{`${t('it_specialist')} & ${t('programmer')}`}</WebsiteSubtitle>
            </TitleContainer>
        </LogoContainer>
    );
};

Logo.propTypes = {
    size: PropTypes.oneOf(['small', 'regular']),
    style: PropTypes.object,
    className: PropTypes.string,
};

Logo.defaultProps = {
    size: 'regular',
};

export default Logo;
