import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';
import StyledButton from '../components/StyledButton/StyledButton';
import ContactContainer from '../sections/Contact/ContactContainer/ContactContainer';

const NotFound = () => {
    const { t } = useTranslation('common');

    return (
        <Layout>
            <Section
                backgroundColor={'#f5f5f5'}
                inView={true}
                subtitle={'404 not found'}
                title={t('not_found')}
                text={t('not_found_text')}
                style={{ paddingBottom: 0 }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <StyledButton link={'/'} title={t('homepage')} />
                </div>
                <ContactContainer inView={true} />
            </Section>
        </Layout>
    );
};

export default NotFound;
