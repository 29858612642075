import React from 'react';
import { MainButton } from './StyledButton.styles';

const StyledButton = ({ link, title, color, onClick }) => (
    <MainButton href={link} color={color} onClick={onClick}>
        {title}
    </MainButton>
);

export default StyledButton;
