import React, { useEffect, useState } from 'react';
import { useCountUp } from 'react-countup';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import useMobileWidth from '../../../hooks/useMobileWidth';
import { fetchGithubStats } from '../../../utils/helpers/fetchData';
import playAnimations from '../../../utils/helpers/playAnimations';
import {
    ImageBackground,
    StatBox,
    StatDesc,
    StatsContainer,
    StatsDiv,
    StatValue,
    WallpaperImage,
} from './ProjectsStatistics.styles';

const ProjectsStatistics = () => {
    const statistics = {
        public_repos: 30,
        private_repos: 70,
        number_of_languages: 30,
        years_exp: new Date().getFullYear() - 2017,
    };

    const { t } = useTranslation('common');
    const [stats] = useState(statistics);
    const isMobileWidth = useMobileWidth();

    const { ref, inView } = useInView({
        threshold: 0.8,
        triggerOnce: true,
    });

    const counterPublicRepos = useCountUp({
        ref: 'counter_public_repos',
        end: stats.public_repos,
        duration: 3,
        startOnMount: isMobileWidth,
    });

    const counterPrivateRepos = useCountUp({
        ref: 'counter_private_repos',
        end: stats.private_repos,
        duration: 3,
        suffix: '+',
        startOnMount: isMobileWidth,
    });

    const counterNumber = useCountUp({
        ref: 'counter_number',
        end: stats.number_of_languages,
        duration: 3,
        suffix: '+',
        startOnMount: isMobileWidth,
    });

    const counterYearsExp = useCountUp({
        ref: 'counter_years_exp',
        end: stats.years_exp,
        duration: 3,
        startOnMount: isMobileWidth,
    });

    const runCounters = () => {
        counterPublicRepos.start();
        counterPrivateRepos.start();
        counterNumber.start();
        counterYearsExp.start();
    };

    useEffect(() => {
        if (playAnimations(inView, isMobileWidth) || isMobileWidth) {
            fetchGithubStats().then(res => counterPublicRepos.update(res.public_repos));

            runCounters();
        }
    }, [inView, isMobileWidth]);

    return (
        <StatsDiv ref={ref}>
            <WallpaperImage />
            <ImageBackground />
            <StatsContainer>
                <StatBox>
                    <StatValue id={'counter_public_repos'}>{'0'}</StatValue>
                    <StatDesc>{t('counter_public_repos_text')}</StatDesc>
                </StatBox>
                <StatBox>
                    <StatValue id={'counter_private_repos'}>{'0'}</StatValue>
                    <StatDesc>{t('counter_private_repos_text')}</StatDesc>
                </StatBox>
                <StatBox>
                    <StatValue id={'counter_number'}>{'0'}</StatValue>
                    <StatDesc>{t('counter_number_text')}</StatDesc>
                </StatBox>
                <StatBox>
                    <StatValue id={'counter_years_exp'}>{'0'}</StatValue>
                    <StatDesc>{t('counter_years_exp_text')}</StatDesc>
                </StatBox>
            </StatsContainer>
        </StatsDiv>
    );
};

export default ProjectsStatistics;
