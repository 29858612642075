import React from 'react';
import { useTranslation } from 'react-i18next';
import ColoredText from '../../../components/ColoredText/ColoredText';
import ContactIcon from '../../../components/ContactIcon/ContactIcon';
import ContactForm from '../../../components/ContactForm/ContactForm';
import {
    ContactIconsContainer,
    ContactImgContainer,
    ContactItemContainer,
    ContactLine,
    ContactProjectsOverview,
    StyledContactContainer,
    StyledContactSubtext,
    StyledContactText,
} from './ContactContainer.styles';
import projectsOverview from '../../../assets/images/projects/projects-overview.png';
import mediaBrowser from '../../../assets/images/projects/media-browser.png';

const ContactContainer = ({ inView }) => {
    const { t } = useTranslation('common');

    return (
        <StyledContactContainer>
            <ContactLine>
                <ContactItemContainer inView={inView} align={'left'}>
                    <StyledContactText>
                        <ColoredText>{'@ '}</ColoredText>
                        {t('contact_info_1')}
                        <ColoredText>{'.\n'}</ColoredText>
                        <StyledContactSubtext>{t('contact_info_2')}</StyledContactSubtext>
                    </StyledContactText>
                    <ContactIconsContainer>
                        <ContactIcon type={'email'} />
                        <ContactIcon type={'github'} />
                        <ContactIcon type={'discord'} itemId={'discord-large'} />
                        <ContactIcon type={'linkedin'} />
                    </ContactIconsContainer>
                </ContactItemContainer>
                <ContactImgContainer inView={inView}>
                    <ContactProjectsOverview src={projectsOverview} />
                </ContactImgContainer>
            </ContactLine>
            <ContactLine>
                <ContactImgContainer inView={inView}>
                    <ContactProjectsOverview src={mediaBrowser} />
                </ContactImgContainer>
                <ContactItemContainer inView={inView} align={'right'}>
                    <ContactForm />
                </ContactItemContainer>
            </ContactLine>
        </StyledContactContainer>
    );
};

export default ContactContainer;
