import './index.css';
import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { hydrate, render } from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import common_en from './translations/en.json';
import common_pl from './translations/pl.json';
import store from './store/store';

i18next.init({
    interpolation: { escapeValue: false },
    lng: localStorage.getItem('language') ?? 'en',
    resources: {
        en: {
            common: common_en,
        },
        pl: {
            common: common_pl,
        },
    },
});

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>,
        rootElement
    );
} else {
    render(
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>,
        rootElement
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
