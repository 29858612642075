import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillWindows } from 'react-icons/ai';
import { BsGlobe, BsMusicNoteBeamed, BsPeople } from 'react-icons/bs';
import { FaLaptopCode, FaYoutube } from 'react-icons/fa';
import { FcAndroidOs, FcLinux } from 'react-icons/fc';
import { FiGithub } from 'react-icons/fi';
import { IoGameController, IoLogoApple, IoTvOutline } from 'react-icons/io5';
import { IconWrapper } from '../../../sections/Projects/CompetitionsSection/CompetitionItem/CompetitionItem.styles';
import { theme } from '../../../themes/theme';
import ColoredText from '../../ColoredText/ColoredText';
import StyledButton from '../../StyledButton/StyledButton';
import {
    CloseButton,
    CloseIcon,
    HobbyKeyword,
    HobbyKeywords,
    HobbyQuote,
    HobbyText,
    HobbyTitle,
    InfoText,
    InfoType,
    ProjectContent,
    ProjectImage,
    ProjectImageContainer,
    ProjectInfo,
    ProjectInfoBlock,
    ProjectInfoContainer,
    ProjectOverviewContainer,
    ProjectText,
    ProjectTitle,
    ProjectType,
    StyledSlide,
    StyledVideo,
    StyledVideoFront,
    TechnologyInfo,
    VideoContainer,
} from './Slide.styles';

const Slide = ({ indexKey, content, slideOpacity, sliderType }) => {
    const { t, i18n } = useTranslation('common');
    const [isOpen, setIsOpen] = useState(false);
    const videoRef = useRef();

    const renderTechnologies = technologies => {
        const len = technologies.length;
        return technologies.map((el, i) => {
            if (len === i + 1) {
                return el;
            }
            return (
                <span key={el}>
                    {el}
                    <span style={{ color: '#555' }}> &#183; </span>
                </span>
            );
        });
    };

    const renderLanguages = languages => {
        const view = [];
        languages.map(country =>
            view.push(
                <img
                    key={country}
                    alt={country}
                    style={{ marginRight: 10 }}
                    width={20}
                    height={12}
                    src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/${country}.svg`}
                    title={country}
                />
            )
        );

        return view;
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play();
        }
    }, [videoRef]);

    return (
        <StyledSlide key={indexKey} slideOpacity={slideOpacity} sliderType={sliderType}>
            {sliderType === 'projects' ? (
                <>
                    <ProjectOverviewContainer>
                        <ProjectTitle>{content.name}</ProjectTitle>
                        <ProjectType>{content.type[i18n.language]}</ProjectType>
                        <ProjectText>{content.shortDescription[i18n.language]}</ProjectText>
                        <StyledButton title={t('see_more')} color={'white'} onClick={() => setIsOpen(true)} />
                    </ProjectOverviewContainer>
                    <ProjectImageContainer>
                        <ProjectImage src={content.image} />
                    </ProjectImageContainer>
                    <ProjectContent isOpen={isOpen}>
                        <CloseButton onClick={() => setIsOpen(false)}>
                            <CloseIcon color={theme.colors.secondary} />
                        </CloseButton>
                        <ProjectInfoContainer>
                            <ProjectTitle>{content.name}</ProjectTitle>
                            <ProjectText textSize={'small'}>{content.description[i18n.language]}</ProjectText>
                            <ProjectInfo>
                                <ProjectInfoBlock>
                                    <ProjectType style={{ marginTop: 20 }}>{t('tech_stack')}</ProjectType>
                                    {content?.technologies?.project?.length && (
                                        <TechnologyInfo>
                                            <InfoType>{t('project')}</InfoType>
                                            <InfoText>
                                                {renderTechnologies(content.technologies.project)}
                                            </InfoText>
                                        </TechnologyInfo>
                                    )}
                                    {content?.technologies?.application?.length && (
                                        <TechnologyInfo>
                                            <InfoType>{t('application')}</InfoType>
                                            <InfoText>
                                                {renderTechnologies(content.technologies.application)}
                                            </InfoText>
                                        </TechnologyInfo>
                                    )}
                                    {content?.technologies?.server?.length && (
                                        <TechnologyInfo>
                                            <InfoType>{t('server')}</InfoType>
                                            <InfoText>
                                                {renderTechnologies(content.technologies.server)}
                                            </InfoText>
                                        </TechnologyInfo>
                                    )}
                                    {content?.technologies?.database?.length && (
                                        <TechnologyInfo>
                                            <InfoType>{t('database')}</InfoType>
                                            <InfoText>
                                                {renderTechnologies(content.technologies.database)}
                                            </InfoText>
                                        </TechnologyInfo>
                                    )}
                                </ProjectInfoBlock>

                                <ProjectInfoBlock withMargin>
                                    <ProjectType style={{ marginTop: 20 }}>{t('technical_info')}</ProjectType>
                                    <TechnologyInfo>
                                        <InfoType minWidth={150}>{t('supported_systems')}</InfoType>
                                        <InfoText>
                                            {content.os.includes('Internet application') && (
                                                <BsGlobe
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        marginRight: 5,
                                                        marginLeft: -3,
                                                    }}
                                                    title={t('internet_application')}
                                                />
                                            )}
                                            {content.os.includes('Windows') && (
                                                <AiFillWindows
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        marginRight: 5,
                                                        marginLeft: -3,
                                                    }}
                                                    title={'Windows'}
                                                />
                                            )}
                                            {content.os.includes('Linux') && (
                                                <FcLinux
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        marginRight: 5,
                                                        marginLeft: -3,
                                                    }}
                                                    title={'Linux'}
                                                />
                                            )}
                                            {content.os.includes('Android') && (
                                                <FcAndroidOs
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        marginRight: 5,
                                                        marginLeft: -3,
                                                    }}
                                                    title={'Android'}
                                                />
                                            )}
                                            {content.os.includes('iOS') && (
                                                <IoLogoApple
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                    }}
                                                    title={'iOS'}
                                                />
                                            )}
                                        </InfoText>
                                    </TechnologyInfo>
                                    {content?.languages?.length && (
                                        <TechnologyInfo>
                                            <InfoType minWidth={150}>{t('supported_languages')}</InfoType>
                                            <InfoText>{renderLanguages(content.languages)}</InfoText>
                                        </TechnologyInfo>
                                    )}
                                    {content?.links && (
                                        <TechnologyInfo style={{ marginTop: 10 }}>
                                            {content?.links?.github && (
                                                <IconWrapper
                                                    key={'github'}
                                                    href={content.links.github}
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                    backgroundColor={'black'}
                                                    borderColor={'#555'}
                                                    title={t('github_info')}>
                                                    <FiGithub
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                    />
                                                </IconWrapper>
                                            )}
                                            {content?.links?.youtube && (
                                                <IconWrapper
                                                    key={'youtube'}
                                                    href={content.links.youtube}
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                    backgroundColor={'black'}
                                                    borderColor={'#555'}
                                                    title={t('yt_info')}>
                                                    <FaYoutube
                                                        style={{
                                                            width: 20,
                                                            height: 20,
                                                        }}
                                                        color={theme.colors.secondary}
                                                    />
                                                </IconWrapper>
                                            )}
                                            {content?.links?.additional && (
                                                <IconWrapper
                                                    key={'additional'}
                                                    href={content.links.additional.url}
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                    backgroundColor={'black'}
                                                    borderColor={'#555'}
                                                    title={content.links.additional.title[i18n.language]}>
                                                    <img
                                                        alt={content.links.additional.title}
                                                        src={content.links.additional.logo}
                                                        width={25}
                                                        height={25}
                                                    />
                                                </IconWrapper>
                                            )}
                                        </TechnologyInfo>
                                    )}
                                </ProjectInfoBlock>
                            </ProjectInfo>
                        </ProjectInfoContainer>
                    </ProjectContent>
                </>
            ) : (
                <>
                    <VideoContainer>
                        <StyledVideo ref={videoRef} loop muted autoPlay playsInline>
                            <source src={content.intro} type={'video/mp4'} />
                        </StyledVideo>
                        <StyledVideoFront />
                    </VideoContainer>
                    <HobbyTitle>
                        <ColoredText>{'< '}</ColoredText>
                        {content.name[i18n.language]}
                        <ColoredText>{' />'}</ColoredText>
                    </HobbyTitle>
                    <HobbyQuote>{content.quote[i18n.language]}</HobbyQuote>
                    <HobbyText>{content.text[i18n.language]}</HobbyText>
                    <HobbyKeywords>
                        {(() => {
                            switch (content.id) {
                                case 0:
                                    return (
                                        <FaLaptopCode
                                            size={20}
                                            color={theme.colors.secondary}
                                            style={{ marginRight: 10 }}
                                        />
                                    );
                                case 1:
                                    return (
                                        <IoGameController
                                            size={20}
                                            color={theme.colors.secondary}
                                            style={{ marginRight: 10 }}
                                        />
                                    );
                                case 2:
                                    return (
                                        <IoTvOutline
                                            size={20}
                                            color={theme.colors.secondary}
                                            style={{ marginRight: 10 }}
                                        />
                                    );
                                case 3:
                                    return (
                                        <BsMusicNoteBeamed
                                            size={20}
                                            color={theme.colors.secondary}
                                            style={{ marginRight: 10 }}
                                        />
                                    );
                                case 4:
                                    return (
                                        <BsPeople
                                            size={20}
                                            color={theme.colors.secondary}
                                            style={{ marginRight: 10 }}
                                        />
                                    );
                                default:
                                    return <div />;
                            }
                        })()}
                        <HobbyKeyword>
                            {content.keywords[i18n.language].map(e => `${e.toUpperCase()}. `)}
                        </HobbyKeyword>
                    </HobbyKeywords>
                </>
            )}
        </StyledSlide>
    );
};

Slide.defaultProps = {
    sliderType: 'projects',
};

export default Slide;
